import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {createFtpUser} from "../../redux/actions/ftpUsers";

const NewFtpUserDialog = ({eventId, onClose, ...props}) => {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const dispatchCreateFtpUser = (e) => {
        e.preventDefault();

        const ftpUser = {
            name: name,
            password: password,
            bucket: eventId
        };

        props.createFtpUser(eventId, ftpUser);
        onClose();
    };

    const handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'passwordConfirmation':
                setPasswordConfirmation(value);
                break;
            default:
                break;
        }
    };

    return (
        <div className="modal modal-custom is-active">
            <div className="modal-background"/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Nuevo usuario FTP</p>
                    <button className="delete" aria-label="close" onClick={onClose}/>
                </header>
                <section className="modal-card-body">
                    <form>
                        <div className="field">
                            <label className="label">Nombre de usuario</label>
                            <div className="control">
                                <input name="name" className="input" type="text" placeholder="" value={name}
                                       onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Contraseña</label>
                            <div className="control">
                                <input name="password" className="input" type="password" placeholder="" value={password}
                                       onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Repetir contraseña</label>
                            <div className="control">
                                <input name="passwordConfirmation" className="input" type="password" placeholder=""
                                       value={passwordConfirmation} onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-primary" onClick={dispatchCreateFtpUser}>Guardar</button>
                    <button className="button" onClick={onClose}>Cancelar</button>
                </footer>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    createFtpUser
};

export default withRouter(connect(null, mapDispatchToProps)(NewFtpUserDialog));