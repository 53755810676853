import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getAllEvents, deleteEvent, resetDeletedEvent } from "../../redux/actions/events";
import { getUser, postUser } from "../../redux/actions/users";
import { useTranslation } from "react-i18next";
import Modal from "../../components/generic/modal";
import { isEmptyObject } from "../../helpers/functions";

const EventsList = ({ ...props }) => {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewsModal, setShowNewsModal] = useState(false);
    const [eventIdToDelete, setEventIdToDelete] = useState(undefined);

    useEffect(() => {
        if (!props.user?.id) props.getUser();
        props.getAllEvents();
    }, []);


    useEffect(() => {
        if (props.user?.role === "NULL" && props.user?.receiveNews === false) setShowNewsModal(true)
    }, [props.user?.role]);

    useEffect(() => {
        if (!props.user?.id) props.getUser();
    }, [props.user]);

    useEffect(() => {
        if (!isEmptyObject(props.deletedEvent)) {
            props.resetDeletedEvent();
            setEventIdToDelete(undefined);
        }
    }, [props.deletedEvent]);

    useEffect(() => {
        setShowDeleteModal(eventIdToDelete !== undefined);
    }, [eventIdToDelete]);

    const onDelete = (id) => {
        setEventIdToDelete(id);
    };

    const onCancelDelete = () => {
        setEventIdToDelete(undefined);
    }

    const onConfirmDelete = () => {
        props.deleteEvent(eventIdToDelete);
        setTimeout(() => {
            setShowDeleteModal(false)
        }, 500);
    };

    const onCancelNews = () => {
        const user = {...props.user};
        user.receiveNews = false;
        user.role = "activeUser";
        props.postUser(user);
        setShowNewsModal(false);
        // props.getUser();
    }

    const onConfirmNews = () => {
        const user = {...props.user};
        user.receiveNews = true;
        user.role = "activeUser";
        props.postUser(user);
        setShowNewsModal(false);
        // props.getUser();
    };

    return (
        <div className="container">
            <section className="section">
                <div className="columns">
                    <div className="column is-6-tablet">
                        <h1>{t('events.title')}</h1>
                    </div>
                    <div className="column is-6-tablet is-right-tablet">
                        <Link to="/events/new" className="button is-primary">
                            {t('events.newEventButton.text')}
                        </Link>
                    </div>
                </div>
            </section>
            <section className="section pt-0">
                {(props.loading || props.loadingUser) && <progress className="progress is-large is-primary"></progress>}
                {(!props.loading && !props.loadingUser) && props.events?.length == 0 && <center><label>{t('events.table.noEventsToShow')}</label></center>}
                {props.events?.length > 0 && (!props.loading && !props.loadingUser) && <table className="table is-fullwidth is-striped">
                    <thead>
                        <tr>
                            <th>{t('events.table.nameHeader')}</th>
                            <th className="is-hidden-touch">{t('events.table.codeHeader')}</th>
                            <th className="is-hidden-touch">{t('events.table.typeHeader')}</th>
                            <th className="is-hidden-touch">{t('events.table.visibleHeader')}</th>
                            <th className="has-text-right">{t('events.table.actionsHeader')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.events.map((event, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>
                                        <Link to={"/events/" + event.id + "/basic"}>
                                            {event.name}
                                        </Link>
                                    </td>
                                    <td className="is-hidden-touch">{event.code}</td>
                                    <td className="is-hidden-touch">{event.eventType}</td>
                                    <td className="is-hidden-touch">{event.active ? t('events.table.eventIsVisible') : t('events.table.eventIsNotVisible')}</td>
                                    <td className="has-text-right">
                                        <div className="dropdown is-hoverable is-right">
                                            <div className="dropdown-trigger">
                                                <button className="button has-icon" aria-haspopup="true"
                                                    aria-controls="dropdown-menu">
                                                    <span className="material-icons">
                                                        more_horiz
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                <div className="dropdown-content">
                                                    <Link to={`/events/` + event.id + "/basic"} className="dropdown-item">
                                                        {t('events.table.editAction')}
                                                    </Link>
                                                    <hr className="dropdown-divider" />
                                                    <a className="dropdown-item has-text-danger" onClick={e => {
                                                        e.preventDefault();
                                                        onDelete(event.id);
                                                    }}>
                                                        {t('events.table.deleteAction')}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>}
            </section>

            {showDeleteModal === true &&
                <Modal
                    title={t('event.deleteEventAlert.title')}
                    message={t('event.deleteEventAlert.message')}
                    showConfirmButton={true}
                    showCancelButton={true}
                    cancelButtonText={t('generic.alertButton.cancel')}
                    confirmButtonText={t('generic.alertButton.delete')}
                    onCancel={onCancelDelete}
                    onConfirm={onConfirmDelete}
                    loading={props.loading}
                />
            }


            {showNewsModal === true &&
                <Modal
                    title={t('newsletter.title')}
                    message={t('newsletter.text')}
                    closable={false}
                    showConfirmButton={true}
                    showCancelButton={true}
                    cancelButtonText={t('newsletter.no')}
                    confirmButtonText={t('newsletter.yes')}
                    onCancel={onCancelNews}
                    onConfirm={onConfirmNews}
                    loading={props.loading}
                />
            }
        </div>
    );
};

const mapStateToProps = state => (
    {
        user: state.usersReducer.user,
        events: state.eventsReducer.events,
        deletedEvent: state.eventsReducer.deletedEvent,
        loading: state.eventsReducer.loading,
        loadingUser: state.usersReducer.loading
    }
);

const mapDispatchToProps =
{
    getAllEvents,
    getUser,
    postUser,
    deleteEvent,
    resetDeletedEvent
}
    ;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsList));