import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Redirect, Route } from "react-router-dom";
import { withOneTabEnforcer } from "../helpers/duplicatedTab";

const PrivateRoute = ({ path, component }) => {
    const [state, setState] = useState('loading');

    useEffect(() => {
        (async function () {
            try {
                await Auth.currentAuthenticatedUser();
                setState('signedIn');
            } catch {
                setState('redirect');
            }
        })()
    }, []);

    if (state === 'loading') return <div className="content-main"><center><progress className="progress is-large is-primary"></progress></center></div>

    return (
        <Route
            path={path}
            render={() => ((state === 'signedIn') ?
                component :
                <Redirect to="/signIn" />)}
        />
    );
}

export default PrivateRoute;