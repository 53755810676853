import api from "./api";

class FtpUsersService {
    getAllByEventId(eventId) {
        return api.get(`/event/${eventId}/ftpUsers`);
    };

    createFtpUser(eventId, ftpUser) {
        return api.post(`/event/${eventId}/ftpUsers`, ftpUser);
    };
}

export default new FtpUsersService();