import React, { useEffect, useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { deleteEvent, resetDeletedEvent } from "../../redux/actions/events";
import { isEmptyObject } from "../../helpers/functions";
import Modal from "../generic/modal";

const EventAside = ({ eventId, onPageSelected, mode, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        if (!isEmptyObject(props.deletedEvent)) {
            props.resetDeletedEvent();
        }
    }, [props.deletedEvent]);

    useEffect(() => {
        setIsNew(window.location.pathname.includes("events/new"));
    }, [])

    const onDelete = () => {
        setShowDeleteModal(true);
    };

    const onCancelDelete = () => {
        setShowDeleteModal(false);
    }

    const onConfirmDelete = () => {
        props.deleteEvent(eventId);
        setTimeout(() => {
            history.push('/events');
        }, 1000);
    };

    return (
        <>
            <aside className="menu mb-1">
                <ul className="menu-list is-flex-mobile flex-wrap-mobile" style={{
                    overflowX: 'auto', /* Agrega una barra de desplazamiento si es necesario */
                    maxWidth: '100%'
                }}>
                    {eventId !== undefined ? <li><a className="material-icons" style={{ fontSize: 26 }} onClick={e => {
                        e.preventDefault();
                        history.push('/events');
                    }}>arrow_back</a></li> : null}
                    {eventId !== undefined ? <hr /> : null}
                    <li><Link to={!isNew ? "/events/" + eventId + "/basic" : "/events/new"} className={mode == "basic" || isNew ? "is-active" : ""} >{t('event.sideNavigation.basicData')}</Link></li>
                    {eventId !== undefined ?
                        <li><Link className={mode == "sponsor" ? "is-active" : ""} to={"/events/" + eventId + "/sponsor"}>{t('event.sideNavigation.sponsors')}</Link></li> : null}
                    {eventId !== undefined ?
                        <li><Link className={mode == "stats" ? "is-active" : ""} to={"/events/" + eventId + "/stats"}>{t('event.sideNavigation.stats')}</Link></li> : null}
                    {eventId !== undefined ?
                        <li><Link className={mode == "photos" ? "is-active" : ""} to={"/events/" + eventId + "/photos"}>{t('event.sideNavigation.photos')}</Link></li> : null}
                    {eventId !== undefined ? <hr /> : null}
                    {eventId !== undefined ? <li><a className="has-text-danger"
                        onClick={e => {
                            e.preventDefault();
                            onDelete();
                        }}><span style={{ color: 'red' }}>{t('event.sideNavigation.delete')}</span></a></li> : null}
                </ul>
            </aside>
            {showDeleteModal === true &&
                <Modal
                    title={t('event.deleteEventAlert.title')}
                    message={t('event.deleteEventAlert.message')}
                    showConfirmButton={true}
                    showCancelButton={true}
                    cancelButtonText={t('generic.alertButton.cancel')}
                    confirmButtonText={t('generic.alertButton.delete')}
                    onCancel={onCancelDelete}
                    onConfirm={onConfirmDelete}
                    loading={props.loading}
                />
            }
        </>
    );
};

const mapStateToProps = state => (
    {
        events: state.eventsReducer.events,
        deletedEvent: state.eventsReducer.deletedEvent,
        loading: state.eventsReducer.loading
    }
);

const mapDispatchToProps =
{
    deleteEvent,
    resetDeletedEvent
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventAside));