import { CLEAN_EVENT_STATS, GET_NUMBER_OF_DELIVERED_PHOTOS, GET_STATS_BY_EVENT_ID, LOADING_STATS, LOADING_STATS_HISTORY, LOADING_STATS_CHART, GET_CREDIT_STATS, GET_CREDIT_FOR_CHART, GET_CREDIT_FOR_HISTORY, GET_NUMBER_OF_INDEXES_FACES, GET_NUMBER_OF_PHOTOS, GET_NUMBER_OF_SELFIES, GET_NUMBER_OF_USERS_ACCESS_BY_CODE, GET_NUMBER_OF_USERS_WITH_MATCH } from "./type";
import StatsService from "../../services/statsService";
import moment from "moment";

export const getStatsByEventId = (eventId) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_STATS,
            payload: true
        });
        const response = await StatsService.getStatsByEventId(eventId);
        dispatch({
            type: GET_STATS_BY_EVENT_ID,
            payload: response.data
        });
        dispatch({
            type: LOADING_STATS,
            payload: false
        });
    } catch (error) {
        console.log(error);
    }
};

export const getNumberOfDeliveredPhotos = (eventId) => async (dispatch) => {
    try {
        const response = await StatsService.getNumberOfDeliveredPhotos(eventId);
        dispatch({
            type: GET_NUMBER_OF_DELIVERED_PHOTOS,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const getNumberOfIndexedFaces = (eventId) => async (dispatch) => {
    try {
        const response = await StatsService.getNumberOfIndexedFaces(eventId);
        dispatch({
            type: GET_NUMBER_OF_INDEXES_FACES,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const getNumberOfPhotos = (eventId) => async (dispatch) => {
    try {
        const response = await StatsService.getNumberOfPhotos(eventId);
        dispatch({
            type: GET_NUMBER_OF_PHOTOS,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const getNumberOfSelfies = (eventId) => async (dispatch) => {
    try {
        const response = await StatsService.getNumberOfSelfies(eventId);
        dispatch({
            type: GET_NUMBER_OF_SELFIES,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const getNumberOfUsersAccessByCode = (eventId) => async (dispatch) => {
    try {
        const response = await StatsService.getNumberOfUsersAccessByCode(eventId);
        dispatch({
            type: GET_NUMBER_OF_USERS_ACCESS_BY_CODE,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const getNumberOfUsersWithMatch = (eventId) => async (dispatch) => {
    try {
        const response = await StatsService.getNumberOfUsersWithMatch(eventId);
        dispatch({
            type: GET_NUMBER_OF_USERS_WITH_MATCH,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const getCreditStats = (params) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_STATS,
            payload: true
        })
        const response = await StatsService.getCreditStats(params);
        dispatch({
            type: GET_CREDIT_STATS,
            payload: response.data[0]
        });
        dispatch({
            type: LOADING_STATS,
            payload: false
        })
    } catch (error) {
        console.log(error);
    }
};

export const getCreditForChart = (params) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_STATS_CHART,
            payload: true
        })
        const response = await StatsService.getCreditStats(params);
        const groupedData = response.data.reduce((acc, item) => {
            if (item.type == "CODE_ACCESS") {
                const formattedDate = moment(item.operationDate * 1000).format('DD/MM/YY');

                const existingItem = acc.find(a => a.operationDate === formattedDate);
                if (existingItem) {
                    existingItem.quantity += item.quantity;
                } else {
                    acc.push({ operationDate: formattedDate, quantity: item.quantity });
                }
            }
            return acc;
        }, []);
        dispatch({
            type: GET_CREDIT_FOR_CHART,
            payload: groupedData
        });
        dispatch({
            type: LOADING_STATS_CHART,
            payload: false
        })
    } catch (error) {
        console.log(error);
    }
};

export const getCreditForHistory = (params) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_STATS_HISTORY,
            payload: true
        })
        const response = await StatsService.getCreditStats(params);
        let arr = [];
        response.data.map(stat => {
            let date = new Date(stat.operationDate * 1000);
            const newStat = {
                ...stat,
                type: stat.type,
                operationDate: moment(date).format('DD/MM/YY'),
                quantity: stat.quantity,
                eventName: stat.eventName || ""
            }
            arr.push(newStat)
        })
        dispatch({
            type: GET_CREDIT_FOR_HISTORY,
            payload: arr
        });
        dispatch({
            type: LOADING_STATS_HISTORY,
            payload: false
        })
    } catch (error) {
        console.log(error);
    }
};

export const cleanEventStats = () => async (dispatch) => {
    dispatch({
        type: CLEAN_EVENT_STATS
    });
};