const Modal = ({title, message, showCancelButton, showConfirmButton, cancelButtonText, confirmButtonText, onCancel, onConfirm, loading, closable = true}) => {
    return (
        <div className="modal is-active">
            <div className="modal-background"/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    {closable && <button className="delete" aria-label="close" onClick={onCancel}/>}
                </header>
                <section className="modal-card-body">
                    <p className="lead">{message}</p>
                </section>
                <footer className="modal-card-foot">
                    {showCancelButton === true && <button className="button" onClick={onCancel}>{cancelButtonText}</button>}
                    {showConfirmButton === true && <button className={loading ? "button is-loading is-primary": "button is-primary"} onClick={onConfirm}>{confirmButtonText}</button>}
                </footer>
            </div>
        </div>
    );
}

export default Modal;