import api from "./api";

class EventsService {
    getAll() {
        return api.get('/event');
    };

    createEvent(event) {
        return api.post('/event', event)
    };

    updateEvent(event) {
        return api.post(`/event/${event.id}`, event)
    };

    deleteEvent(id) {
        return api.delete(`/event/${id}`)
    };
}

export default new EventsService();