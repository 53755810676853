import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    createEvent,
    getEventById,
    deselectEvent,
    resetCreatedEvent,
    updateEvent,
    resetUpdatedEvent
} from "../../../redux/actions/events";
import { connect } from "react-redux";
import EventForm from "../../../components/events/eventForm";
import { getFileExtension, isEmptyObject } from "../../../helpers/functions";
import { Storage } from "aws-amplify";

const EventBasicData = ({ id, ...props }) => {
    const [event, setEvent] = useState(undefined);
    const [imageToUpload, setImageToUpload] = useState(undefined);
    const [eventId, setEventId] = useState(id);
    const [eventPosterImageName, setEventPosterImageName] = useState(undefined);

    const resetHooks = () => {
        setEvent(undefined);
        setImageToUpload(undefined);
        setEventId(undefined);
        setEventPosterImageName(undefined);
    };

    useEffect(() => {
        if (id) {
            props.getEventById(id);
        } else
            props.deselectEvent();
    }, []);

    useEffect(() => {
        if (id !== undefined && !isEmptyObject(props.selectedEvent)) {
            setEvent(props.selectedEvent);
        }
    }, [props.selectedEvent]);

    useEffect(() => {
        if (!isEmptyObject(props.updatedEvent)) {
            resetHooks();
            props.resetUpdatedEvent();
            props.deselectEvent();
            props.history.replace('/events');
        }
    }, [props.updatedEvent]);

    useEffect(() => {
        if (imageToUpload !== undefined && eventId !== undefined && eventPosterImageName !== undefined) {
            dispatchUploadImage(eventId, eventPosterImageName, imageToUpload).then(() => {
                resetHooks();
                props.resetCreatedEvent();
                props.resetUpdatedEvent();
                props.deselectEvent();
                props.history.replace('/events');
            });
        }
    }, [imageToUpload]);

    useEffect(() => {
        if (!isEmptyObject(props.createdEvent)) {
            const id = props.createdEvent.id;
            if(id) {
                props.resetCreatedEvent();
                props.resetUpdatedEvent();
                props.deselectEvent();
                props.getEventById(id);
                props.history.replace(`/events/${props.createdEvent.id}/basic`);
            }
            resetHooks();
        }
    }, [props.createdEvent]);

    const dispatchCreateEvent = (name, dateFrom, dateTo, eventType, active, posterImageName, hasCustomPosterImage) => {
        const event = {
            name: name,
            eventType: eventType,
            dateFrom: dateFrom,
            dateTo: dateTo,
            active: active,
            posterImageName: posterImageName,
            hasCustomPosterImage: hasCustomPosterImage
        };

        props.createEvent(event);
    };

    const dispatchUpdateEvent = (name, code, dateFrom, dateTo, eventType, active, posterImageName, hasCustomPosterImage) => {
        const event = {
            id: id,
            name: name,
            code: code,
            eventType: eventType,
            dateFrom: dateFrom,
            dateTo: dateTo,
            active: active,
            posterImageName: posterImageName,
            hasCustomPosterImage: hasCustomPosterImage
        };

        props.updateEvent(event);
    };

    const dispatchCreateOrUpdate = (isCreation, name, code, dateFrom, dateTo, eventType, image, active, hasCustomPosterImage) => {
        let posterImageName = "EventPoster.jpg";
        let auxCustomPosterImage = undefined;
        if (image !== undefined) {
            auxCustomPosterImage = true;
            const extension = getFileExtension(image.name);
            posterImageName = `EventPoster.${extension}`;
            setEventPosterImageName(posterImageName);
            setImageToUpload(image);
        }

        isCreation ?
            dispatchCreateEvent(name, dateFrom, dateTo, eventType, active, posterImageName, auxCustomPosterImage || hasCustomPosterImage) :
            dispatchUpdateEvent(name, code, dateFrom, dateTo, eventType, active, posterImageName, auxCustomPosterImage || hasCustomPosterImage);

    };

    const dispatchUploadImage = async (id, posterImageName, image) => {
        const path = `${id}/Configuration/${posterImageName}`;
        await Storage.put(path, image);
    };

    const dispatchCancel = () => {
        resetHooks();
        props.deselectEvent();
        props.history.replace('/events');
    };

    return (
        <EventForm
            event={event}
            dispatchCreateOrUpdate={dispatchCreateOrUpdate}
            dispatchCancel={dispatchCancel}
            loading={props.loading}
        />
    );
};

const mapStateToProps = state => (
    {
        selectedEvent: state.eventsReducer.selectedEvent,
        createdEvent: state.eventsReducer.createdEvent,
        updatedEvent: state.eventsReducer.updatedEvent,
        loading: state.eventsReducer.loading
    }
);

const mapDispatchToProps =
{
    createEvent,
    getEventById,
    deselectEvent,
    resetCreatedEvent,
    updateEvent,
    resetUpdatedEvent
}
    ;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventBasicData));