import React, { useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getCreditStats, getCreditForChart, getCreditForHistory } from "../../redux/actions/stats";
import { getUser, postUser } from "../../redux/actions/users";
import 'react-toastify/dist/ReactToastify.css';
import AccountAside from "../navigation/accountAside";
import AccountBasicData from "../../pages/account/accountBasicData";
import AccountCredit from "../../pages/account/accountCredit";
import { useTranslation } from 'react-i18next';

const AccountContainer = ({ user, getUser, credits, creditsChart, creditsHistory, getCreditStats, getCreditForChart, getCreditForHistory, loadingChart, loadingHistory, loadingUser, postUser }) => {

    let { mode } = useParams();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!user) getUser();
        if(mode === "credit"){
            if (!credits) getCreditStats({ pageSize: '1' });
            if (!creditsChart) getCreditForChart({ pageSize: '60' });
            if (!creditsHistory) getCreditForHistory({ pageSize: '20' });
        }
    }, [mode]);

    useEffect(() => {
        if (i18n.language !== user?.lang) i18n.changeLanguage(user?.lang)
    }, [user]);

    return (
        <main className="main">
            <section className="section section-has-sidebar">
                <AccountAside mode={mode} />
                <div className="content-main">
                    {mode === "basic" && <AccountBasicData user={user} loading={loadingUser} postUser={postUser} />}
                    {/* {mode == "funding" && <AccountFunding credits={credits} list={buyListExample} />} */}
                    {mode === "credit" && <AccountCredit
                        credits={credits}
                        creditsChart={creditsChart}
                        creditsHistory={creditsHistory}
                        getCreditStats={getCreditStats}
                        getCreditForChart={getCreditForChart}
                        getCreditForHistory={getCreditForHistory}
                        loadingChart={loadingChart} 
                        loadingHistory={loadingHistory}/>}
                </div>
            </section>
        </main>
    );
};

const mapStateToProps = state => (
    {
        user: state.usersReducer.user,
        credits: state.statsReducer.credits,
        creditsChart: state.statsReducer.creditsChart,
        creditsHistory: state.statsReducer.creditsHistory,
        loadingUser: state.usersReducer.loading,
        loadingChart: state.statsReducer.loadingChart,
        loadingHistory: state.statsReducer.loadingHistory,
    }
);

const mapDispatchToProps =
{
    getUser,
    postUser,
    getCreditStats,
    getCreditForChart,
    getCreditForHistory
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountContainer));