export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
export const CREATE_EVENT = 'CREATE_EVENT';
export const DESELECT_EVENT = 'DESELECT_EVENT';
export const RESET_CREATED_EVENT = 'RESET_CREATED_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const RESET_UPDATED_EVENT = 'RESET_UPDATED_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const RESET_DELETED_EVENT = 'RESET_DELETED_EVENT';
export const LOADING_EVENT = 'LOADING_EVENT';

export const GET_ALL_FTP_USERS_BY_EVENT_ID = 'GET_ALL_FTP_USERS_BY_EVENT';
export const CLEAN_FTP_USERS = 'CLEAN_FTP_USERS';
export const CREATE_FTP_USER = 'CREATE_FTP_USER';

export const GET_STATS_BY_EVENT_ID = 'GET_STATS_BY_EVENT_ID';
export const GET_NUMBER_OF_DELIVERED_PHOTOS = 'GET_NUMBER_OF_DELIVERED_PHOTOS';
export const GET_NUMBER_OF_INDEXES_FACES = 'GET_NUMBER_OF_INDEXES_FACES';
export const GET_NUMBER_OF_PHOTOS = 'GET_NUMBER_OF_PHOTOS';
export const GET_NUMBER_OF_SELFIES = 'GET_NUMBER_OF_SELFIES';
export const GET_NUMBER_OF_USERS_ACCESS_BY_CODE = 'GET_NUMBER_OF_USERS_ACCESS_BY_CODE';
export const GET_NUMBER_OF_USERS_WITH_MATCH = 'GET_NUMBER_OF_USERS_WITH_MATCH';
export const GET_NUMBER_OF_GLOBAL_MATCHES = 'GET_NUMBER_OF_GLOBAL_MATCHES';
export const CLEAN_EVENT_STATS = 'CLEAN_EVENT_STATS';
export const LOADING_STATS = 'LOADING_STATS';
export const LOADING_STATS_CHART = 'LOADING_STATS_CHART';
export const LOADING_STATS_HISTORY = 'LOADING_STATS_HISTORY';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const RESET_SIGN_IN_ERROR_MESSAGE = '';
export const LOADING_USER = 'LOADING_USER';
export const GET_USER = 'GET_USER';
export const POST_USER = 'POST_USER';
export const GET_CREDIT_STATS = 'GET_CREDIT_STATS';
export const GET_CREDIT_FOR_CHART = 'GET_CREDIT_FOR_CHART';
export const GET_CREDIT_FOR_HISTORY = 'GET_CREDIT_FOR_HISTORY';