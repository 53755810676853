import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getEventById } from "../../../redux/actions/events";
import Modal from "../../../components/generic/modal";
import { toast } from "react-toastify";
import { ERROR_TOAST_CONFIG } from "../../../helpers/functions";
import EXIF from 'exif-js';


const EventPhotos = (props) => {

    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams();
    const MAX_RETRIES = 3;
    let toastId = null;
    let toastId2 = null;
    let concurrentS3Operations = 1;

    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [counter, setCounter] = useState(0);
    const [handlerCounter, setHandlerCounter] = useState(-1);
    const [handlerTotal, setHandlerTotal] = useState(-1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentPhotos, setCurrentPhotos] = useState([]);
    const [duplicatedPhotos, setDuplicatedPhotos] = useState([]);
    const [readyToUpload, setReadyToUpload] = useState([]);
    const [speedInMBPerSecond, setSpeedInMBPerSecond] = useState(undefined);

    useEffect(() => {
        if (id && props.user) {
            props.getEventById(id);
            async function fetch() {
                await getCurrentPhotos()
            };
            fetch();
        }
    }, [id, props.user])

    useEffect(() => {
        if (uploading) {
          // Bloquear la navegación
          const unblock = history.block(t('generic.alertButton.leavePageOnUploading'));
          
          return () => {
            // Desbloquear la navegación cuando se complete la subida
            unblock();
          };
        }
      }, [uploading]);

    const getCurrentPhotos = async () => {
        let nextToken = undefined;
        let hasNextPage = true;
        const arr = [];
        try {
            setLoading(true)
            do {
                let response = await Storage.list(`${id}/FtpUsers/${props.user.id}/`, { pageSize: 'ALL', nextToken: nextToken })
                response?.results.forEach(f => {
                    arr.push(f.key)
                })
                if (response.hasNextToken) {
                    nextToken = response.nextToken;
                    hasNextPage = true
                } else hasNextPage = false;
            } while (hasNextPage)

            setCurrentPhotos(arr)
            setLoading(false)

        } catch (e) {
            console.log(e)
        }
    }

    async function calculateFileHash(file) {
        const hashAlgorithm = 'SHA-256';
        const buffer = await file.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest(hashAlgorithm, buffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }

    const cleanHooks = async () => {
        await getCurrentPhotos();
        setReadyToUpload(undefined);
        setDuplicatedPhotos(undefined);
        setCounter(0);
        setLoading(false);
        setUploading(false);
        setDeleting(false);
        window.removeEventListener('beforeunload', handleBeforeUnload);
        document.getElementById("uploadPhotosInput").value = "";
    }

    const getUploadSpeed = (startTime, size) => {
        const endTime = new Date().getTime();
        const elapsedTime = (endTime - startTime) / 1000; // en segundos

        const speedInBytesPerSecond = size / elapsedTime;
        const speed = (speedInBytesPerSecond / 1048576) * 8; // Convertir a Mbps
        setSpeedInMBPerSecond(speed.toFixed(2));
        determineConcurrentS3Operations(speed.toFixed(2));
    }

    const getFileDate = (file) => {
        return new Promise((resolve, reject) => {
            function convertToTimestamp(exifDate) {
                const [datePart, timePart] = exifDate.split(' ');
                const [year, month, day] = datePart.split(':');
                const [hour, minute, second] = timePart.split(':');
                const date = new Date(year, month - 1, day, hour, minute, second);
                return date.getTime();
            }
    
            EXIF.getData(file, function() {
                const allTags = EXIF.getAllTags(this);
                let dateCapture;
                if(allTags.DateTimeOriginal && allTags.DateTimeOriginal !== "0000:00:00 00:00:00") {
                    dateCapture = convertToTimestamp(allTags.DateTimeOriginal);
                } else if(allTags.DateTimeDigitized && allTags.DateTimeDigitized !== "0000:00:00 00:00:00") {
                    dateCapture = convertToTimestamp(allTags.DateTimeDigitized);
                } else if(allTags.DateTime && allTags.DateTime !== "0000:00:00 00:00:00") {
                    dateCapture = convertToTimestamp(allTags.DateTime);
                } else {
                    dateCapture = file.lastModified;
                }
                resolve(dateCapture);
            });
        });
    }
    

    async function uploadOneFile(file, key, retries = 0) {
        try {
            const photoDate = await getFileDate(file);
            const startTime = new Date().getTime();
            const result = await Promise.race([
                Storage.put(key, file, {
                    metadata: { "original-name": file.name, "photo-date": photoDate }
                }),
                new Promise((_, reject) =>
                    setTimeout(() => reject(new Error('Network timeout')), 15000) // Tiempo de espera de 30 segundos
                )
            ]);
            if (toastId !== null) { // Si se está mostrando un toast de error
                toast.dismiss(toastId); // Descartarlo
                toastId = null; // Resetear el ID del toast
            }
            getUploadSpeed(startTime, file.size);

            return result;
        } catch (error) {
            if (retries < MAX_RETRIES) {
                await new Promise(resolve => setTimeout(resolve, retries * 5000)); // Espera un poco antes de reintentar
                if (toastId === null || !toast.isActive(toastId)) toastId = toast(t('generic.errorAlert.networkError'), { ...ERROR_TOAST_CONFIG, type: 'error' });
                return uploadOneFile(file, key, retries + 1);
            } else {
                throw error;
            }
        }
    }

    async function deleteOneFile(key) {
        return await Storage.remove(key);
    }

    const getKeyHash = async (file) => {
        const hash = await calculateFileHash(file);
        const ext = file.name.split('.').pop();
        const key = `${id}/FtpUsers/${props.user.id}/${hash}.${ext}`;
        return key;
    }

    const onDelete = async () => {
        setShowDeleteModal(false);
        setDeleting(true);
        let clone = 0;

        const deleteQueue = [...currentPhotos];

        const concurrentDeletes = determineCPUType() * 10;

        console.log(concurrentDeletes)

        while (deleteQueue.length > 0) {
            const currentBatch = deleteQueue.splice(0, concurrentDeletes); // Obtener un grupo de archivos para eliminar

            await Promise.all(currentBatch.map(async file => {
                await deleteOneFile(file);
                clone = clone + 1;
                setCounter(clone);
            }));
        }

        await cleanHooks();
    };

    function handleBeforeUnload(e) {
        e.preventDefault();
        e.returnValue = '';
    }

    const determineCPUType = () => {
        // CPU multiplicator
        const fastCPU = 4;
        const normalCPU = 2;
        const slowCPU = 1;

        const startTime = performance.now();
        for (let i = 0; i < 10000000; i++) { } // medir el tiempo de ejecución
        const endTime = performance.now();
        const elapsedTime = endTime - startTime;
        const cpuType = elapsedTime < 50 ? fastCPU : (elapsedTime < 150 ? normalCPU : slowCPU);
        return cpuType;
    }

    const determineConcurrentS3Operations = (speed) => {
        // Calcular el tamaño total y promedio de los archivos en readyToUpload
        const totalSizeMB = readyToUpload?.reduce((acc, obj) => acc + obj.file.size, 0) / (1024 * 1024);
        
        const averageSizeMB = totalSizeMB / readyToUpload.length;
        // Usar la velocidad de subida y el tamaño promedio para determinar el número de subidas concurrentes
        let concurrentUploads = Math.round(speed / averageSizeMB);
        // Asegurarse de que no sea menor que 1
        concurrentUploads = Math.max(1, concurrentUploads);
        
        concurrentS3Operations = concurrentUploads;
    }
    

    const onUpload = async () => {
        const uploadQueue = [...readyToUpload];

        setUploading(true);
        window.addEventListener('beforeunload', handleBeforeUnload);

        let firstFile = true;
        while (uploadQueue.length > 0) {
            if (firstFile) { // en primera subida se calcula la velocidad
                const obj = uploadQueue.shift();
                await uploadOneFile(obj.file, obj.key);
                setCounter(counter => counter + 1);
                firstFile = false;
            }

            const currentBatch = uploadQueue.splice(0, concurrentS3Operations); // Obtener un grupo de archivos para subir
            await Promise.all(currentBatch.map(async obj => {
                try {
                    await uploadOneFile(obj.file, obj.key); // Intentar subirlo
                    setCounter(counter => counter + 1); // Incrementar el contador de archivos subidos
                    if (toastId2 !== null) { // Si se está mostrando un toast de error
                        toast.dismiss(toastId2); // Descartarlo
                        toastId2 = null; // Resetear el ID del toast
                    }
                } catch (error) {
                    // Si hubo un error y se agotaron los intentos, eliminar el archivo de la cola y continuar con el siguiente
                    if (toastId2 === null || !toast.isActive(toastId2)) { // Si no se está mostrando un toast de error
                        toastId2 = toast(t('generic.errorAlert.networkErrorFile') + obj.file.name + ". Error: " + error, { ...ERROR_TOAST_CONFIG, type: "error" });
                    }
                }
            }));
        }

        await cleanHooks();
    }

    const handleSelectFiles = async (files) => {
        const readyClone = new Set(readyToUpload?.map(obj => obj.key) || []);
        const currentClone = new Set(currentPhotos || []);
        const duplicatedClone = new Set(duplicatedPhotos || []);
        const newReady = [];
        const newDuplicated = [];

        setHandlerTotal(files.length);

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            try {
                const key = await getKeyHash(file);
                if (readyClone.has(key) || currentClone.has(key) || duplicatedClone.has(key)) {
                    newDuplicated.push(key);
                } else {
                    newReady.push({ file: file, key: key });
                }
                setHandlerCounter(i);
            } catch (e) {
                console.log(e);
            }
        }

        setReadyToUpload(prev => Array.isArray(prev) ? [...prev, ...newReady] : [...newReady]);
        setDuplicatedPhotos(prev => Array.isArray(prev) ? [...prev, ...newDuplicated] : [...newDuplicated]);
        setHandlerCounter(-1);
    };



    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files)
        handleSelectFiles(chosenFiles);
    };


    return (<div>
        <h2 className="mb-1">{t('event.photos.title')}</h2>
        <div className="card stat3">
            <div className="card-content">
                <div className="content">
                    <div className="columns is-centered">
                        <div className="column is-one-third">
                            <div className="content">
                                <div className="has-text-centered">
                                    {deleting && <>
                                        <p className="has-text-centered">
                                            <span className="material-icons is-large has-color-gray">
                                                delete
                                            </span>
                                        </p>
                                        <progress className="progress is-large is-primary" value={counter || 0} max={currentPhotos?.length || 0}></progress>
                                        <label>{counter || 0}/{currentPhotos?.length || 0} photos borradas</label></>}
                                    {!deleting && <>
                                        <p className="has-text-centered">
                                            <span className="material-icons is-large has-color-gray">
                                                photo
                                            </span>
                                        </p>
                                        <p className="qty is-large has-text-centered mt-1 mb-0" style={{ height: 45 }}>{currentPhotos ? currentPhotos.length : "-"}</p>
                                        <div className="has-text-centered">
                                            <p className="has-text-centered">{t('event.photos.currentPhotos')}</p>
                                            <button className="button m-1"
                                                onClick={() => setShowDeleteModal(true)} disabled={!currentPhotos || currentPhotos?.length < 1}>{t('event.photos.delete')}</button>
                                        </div></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="card stat3 mt-1">
            <div className="card-content">
                <div className="columns">
                    <div className="column is-one-third-desktop">
                        {!uploading
                            ? (handlerCounter < 0
                                ? <>
                                    <label htmlFor="uploadPhotosInput"
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                        className="input is-clickable is-hoverable is-justify-content-center is-align-content-center"
                                        style={{
                                            border: '1px dashed #9146FF',
                                            height: '100%'
                                        }} >
                                        <div className="has-text-centered">
                                            <span className="material-icons is-large" style={{ color: '#9146ff73' }}>backup</span>
                                            <br></br>
                                            <br></br>
                                            <p>{t('event.photos.drop')}</p>
                                        </div>
                                    </label>
                                    <input id="uploadPhotosInput" className="is-hidden" draggable={true}
                                        type="file" multiple="multiple" accept="image/png, image/jpeg"
                                        onChange={(e) => {
                                            const chosenFiles = Array.prototype.slice.call(e.target.files);
                                            handleSelectFiles(chosenFiles);
                                        }} />
                                </>
                                : <div className="content" style={{ marginTop: '20%' }}>
                                    <div className="has-text-centered">
                                        <progress className="progress is-large is-primary" value={handlerCounter} max={handlerTotal}></progress>
                                        <label>{handlerCounter || 0}/{handlerTotal || 0}</label>
                                    </div>
                                </div>)
                            : <div className="content">
                                <div className="has-text-centered">
                                    <p className="has-text-centered">
                                        <span className="material-icons is-large has-color-gray" style={{ transform: 'rotateZ(180deg)' }}>
                                            downloading
                                        </span>
                                    </p>
                                    <progress className="progress is-large is-primary" value={counter || 0} max={readyToUpload?.length || 0}></progress>
                                    <label>{counter || 0}/{readyToUpload?.length || 0} photos subidas</label>
                                    <p>{speedInMBPerSecond && `${speedInMBPerSecond} Mbps`}</p>
                                </div>
                            </div>}
                    </div>
                    <div className="column is-one-third-desktop">
                        <div className="content">
                            <div className="has-text-centered">
                                <p className="has-text-centered">
                                    <span className="material-icons is-large has-color-gray">
                                        check
                                    </span>
                                </p>
                                {loading
                                    ? <progress className="progress is-large is-primary" />
                                    : <p className="qty is-large has-text-centered mt-1 mb-0" style={{ height: 45 }}>{readyToUpload?.length > 0 ? readyToUpload.length : "-"}</p>}
                                <div className="has-text-centered">
                                    <p className="has-text-centered">{t('event.photos.readyToUpload')}</p>
                                    <button className="button is-primary m-1"
                                        onClick={onUpload} disabled={uploading || !readyToUpload || readyToUpload?.length < 1}>{t('event.photos.upload')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third-desktop">
                        <div className="content">
                            <p className="has-text-centered">
                                <span className="material-icons is-large has-color-gray">
                                    repeat
                                </span>
                            </p>
                            {loading
                                ? <progress className="progress is-large is-primary" />
                                : <p className="qty is-large has-text-centered mt-1 mb-0" style={{ height: 45 }}>{duplicatedPhotos?.length > 0 ? duplicatedPhotos.length : "-"}</p>}
                            <div className="has-text-centered">
                                <p className="has-text-centered">{t('event.photos.repeatedPhotos')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {showDeleteModal &&
            <Modal
                title="Eliminar fotos"
                message={t('event.photos.deleteConfirm')}
                showConfirmButton={true}
                showCancelButton={true}
                cancelButtonText="Cancelar"
                confirmButtonText="Borrar"
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={onDelete}
                loading={loading}
            />}
    </div>)

};

const mapStateToProps = state => (
    {
        user: state.usersReducer.user
    }
);

const mapDispatchToProps = {
    getEventById,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventPhotos));
