import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AccountForm = ({ user, postUser, ...props }) => {
    const { t } = useTranslation();

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            "id": user?.id,
            "name": user?.name,
            "email": user?.email,
            "receiveNews": user?.receiveNews,
            "lang": user?.lang || 'es',
            "role": "activeUser"
        }
    });

    const onSubmit = (data) => {
        postUser(data)
    };

    const onError = (err, e) => console.log(err, e);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <h2 className="mb-1">{t('event.basicData.title')}</h2>
                {user && <>
                    <div className="columns mb-1">
                        <div className="column is-auto-tablet">
                            <label className="label">{t('account.basicData.name.label')}</label>
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <input className="input" type="text" placeholder="" {...register('name')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns mb-1">
                        <div className="column is-auto-tablet">
                            <label className="label">{t('account.basicData.email.label')}</label>
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <input className="input" type="text" placeholder="" disabled {...register('email')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns mb-1">
                        <div className="column is-auto-tablet">
                            <label className="label">{t('account.basicData.news.label')}</label>
                            <div className="onoffswitch mr-1">
                                <input data-testid="receiveNews" type="checkbox" name="receiveNews"
                                    className="onoffswitch-checkbox"
                                    id="receiveNews" tabIndex="0"
                                    {...register("receiveNews")} />
                                <label className="onoffswitch-label" htmlFor="receiveNews">
                                    <span className="onoffswitch-inner" />
                                    <span className="onoffswitch-switch" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="columns mb-1">
                        <div className="column is-auto-tablet">
                            <label className="label" htmlFor="lang">{t('account.basicData.lang.label')}</label>
                            <div className="select">
                                <select
                                    data-testid="lang"
                                    name="lang"
                                    id="lang"
                                    {...register("lang")}
                                >
                                    <option value="es">{t('account.basicData.lang.es')}</option>
                                    <option value="en">{t('account.basicData.lang.en')}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="buttons-end is-xs-fixed mt-2">
                        <div className="buttons">
                            <button data-testid="saveOrCreate" type="submit"
                                className={props?.loading ? "button is-loading is-primary is-fullwidth-mobile" : "button is-primary is-fullwidth-mobile"}>
                                {t('generic.saveButton.text')}
                            </button>
                        </div>
                    </div>
                </>}
            </form>
        </>
    );
}

export default AccountForm;