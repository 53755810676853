import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { signOut, getUser } from "../../redux/actions/users";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEventById, deselectEvent } from "../../redux/actions/events";

const Nav = (props) => {

    const { t } = useTranslation();
    const [user, setUser] = useState(undefined);
    const [burgerMenuActive, setBurgerMenuActive] = useState(false)

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    return (
        <div id="nav">
            <nav className="navbar navbar-main is-dark is-fixed-top">

                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/events" className="navbar-item is-active">
                            <img
                                src="https://rockthesportv2.blob.core.windows.net/configuracion/mockups/picblink/images/logo.svg"
                                alt="Picblink" className="logo-nav" />
                        </Link>
                        {user?.email && <a onClick={() => setBurgerMenuActive(!burgerMenuActive)} role="button"
                            className={`navbar-burger burger ${burgerMenuActive ? 'is-active' : ''}`}
                            aria-label="menu" aria-expanded="false" data-target="navMenu">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>}
                    </div>
                    <div id="navMenu" className={`navbar-menu ${burgerMenuActive ? 'is-active' : ''}`}>
                        {props.event?.name && props.location?.pathname != '/events' && <div className="navbar-start d-flex ml-1">
                            <span>{props.event?.name}</span>
                            <span className="has-text-primary">&nbsp;|&nbsp;</span>
                            <span >{props.event?.code}</span>
                        </div>}
                        <div className="navbar-end">
                            {user?.balance && <Link className="navbar-item is-hidden-mobile"
                                onClick={() => setBurgerMenuActive(!burgerMenuActive)} to="/account/credit" title={t('nav.menu.credit')}>
                                <span className="material-icons d-flex p-2">
                                    person_add
                                </span>
                                {user?.balance}
                            </Link>}
                            {user?.email && <div onClick={() => setBurgerMenuActive(!burgerMenuActive)} className="navbar-item has-dropdown is-hoverable">
                                <span className="navbar-link d-flex is-hidden-mobile">
                                    <span>{user.name}</span>
                                </span>
                                <div className="navbar-dropdown">
                                    <Link onClick={() => setBurgerMenuActive(!burgerMenuActive)} className="navbar-item" to="/account/basic">
                                        <span className="material-icons d-flex p-2">
                                            person
                                        </span>
                                        {t('nav.menu.account')}
                                    </Link>
                                    <Link onClick={() => setBurgerMenuActive(!burgerMenuActive)} className="navbar-item" to="/events">
                                        <span className="material-icons d-flex p-2">
                                            event
                                        </span>
                                        {t('nav.menu.events')}
                                    </Link>
                                    <Link className="navbar-item" to="" onClick={props.signOut}>
                                        <span className="material-icons d-flex p-2">
                                            logout
                                        </span>
                                        {t('nav.menu.signOut')}
                                    </Link>
                                </div>
                            </div>}
                            <a className="navbar-item material-icons d-flex is-hidden-mobile" style={{ maxWidth: 35 }} target="_blank" href="https://picblink.com/help">
                                help_large
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
};

const mapStateToProps = state => (
    {
        user: state.usersReducer.user,
        loading: state.usersReducer.loading,
        event: state.eventsReducer.selectedEvent
    }
);

const mapDispatchToProps =
{
    signOut,
    getUser,
    getEventById,
    deselectEvent
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));