import {
    CREATE_EVENT,
    DESELECT_EVENT,
    GET_ALL_EVENTS,
    GET_EVENT_BY_ID,
    RESET_CREATED_EVENT,
    UPDATE_EVENT,
    DELETE_EVENT,
    RESET_DELETED_EVENT,
    RESET_UPDATED_EVENT,
    LOADING_EVENT
} from "./type";
import EventsService from "../../services/eventsService";

export const getAllEvents = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_EVENT,
            payload: true
        })
        const response = await EventsService.getAll();
        dispatch({
            type: GET_ALL_EVENTS,
            payload: response.data
        });
        dispatch({
            type: LOADING_EVENT,
            payload: false
        })
    } catch (error) {
        console.log(error);
    }
};

export const getEventById = (id) => async (dispatch) => {
    dispatch({
        type: GET_EVENT_BY_ID,
        payload: id
    });
};

export const deselectEvent = () => async (dispatch) => {
    dispatch({
        type: DESELECT_EVENT
    });
};

export const resetCreatedEvent = () => async (dispatch) => {
    dispatch({
        type: RESET_CREATED_EVENT
    });
};

export const createEvent = (event) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_EVENT,
            payload: true
        })

        const response = await EventsService.createEvent(event);

        dispatch({
            type: CREATE_EVENT,
            payload: response.data
        });

        dispatch({
            type: LOADING_EVENT,
            payload: false
        })
    } catch (err) {
        console.log(err);
    }
};

export const updateEvent = (event) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_EVENT,
            payload: true
        })

        const response = await EventsService.updateEvent(event);

        dispatch({
            type: UPDATE_EVENT,
            payload: response.data
        });

        dispatch({
            type: LOADING_EVENT,
            payload: false
        })
    } catch (err) {
        console.log(err);
    }
};

export const deleteEvent = (id) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_EVENT,
            payload: true
        })

        await EventsService.deleteEvent(id);

        dispatch({
            type: DELETE_EVENT,
            payload: id
        });

        dispatch({
            type: LOADING_EVENT,
            payload: false
        })
    } catch (err) {
        console.log(err);
    }
};

export const resetDeletedEvent = () => async (dispatch) => {
    dispatch({
        type: RESET_DELETED_EVENT
    });
};

export const resetUpdatedEvent = () => async (dispatch) => {
    dispatch({
        type: RESET_UPDATED_EVENT
    });
};