export const isEmptyObject = (obj) => {
    return obj === undefined || Object.keys(obj).length === 0
};

export const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
};

export const isValidImageExtension = (filename) => {
    const extension = getFileExtension(filename);
    return ["jpg", "jpeg", "png"].includes(extension);
};

export const isPngExtension = (filename) => {
    const extension = getFileExtension(filename);
    return extension === "png";
};

export const ERROR_TOAST_CONFIG = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    type: "success",
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
};