import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import EventContainer from "../components/events/eventContainer";
import EventsList from "../pages/events/eventsList";
import { Hub } from 'aws-amplify';
import AccountCointainer from "../components/account/accountCointainer";
import CognitoLanding from "../pages/auth/cognito";
import Nav from "../components/navigation/nav";
import Footer from "../components/navigation/footer";
import { ToastContainer } from "react-toastify";
import { withOneTabEnforcer } from "../helpers/duplicatedTab";

export const RouterConfig = () => {
    const history = useHistory();

    const listener = (data) => {
        switch (data.payload.event) {
            case 'signIn':
                // user signed in
                history.replace("/events");
                break;
            case 'signUp':
                // user signed up
                break;
            case 'signOut':
                // user signed out
                history.replace("/signIn");
                break;
            case 'signIn_failure':
                // user sign in failed
                break;
            case 'autoSignIn_failure':
                history.replace("/signIn");
                break;
            case 'tokenRefresh':
                // token refresh succeeded
                break;
            case 'tokenRefresh_failure':
                // token refresh failed
                break;
            case 'configured':
                // the Auth module is configured
                break;
            default:
                break;
        }
    }
    Hub.listen('auth', listener);

    const hoc = (Component) => {
        const OnlyOneTabComponent = withOneTabEnforcer()(Component)
        return (props) => (
            <>
                <ToastContainer />
                <Nav />
                <div id="root">
                    <OnlyOneTabComponent {...props} />
                </div>
                <Footer />
            </>
        );
    }

    const WrappedEventContainer = hoc(EventContainer);
    const WrappedAccountCointainer = hoc(AccountCointainer);
    const WrappedEventsList = hoc(EventsList);

    return (
        <Switch>
            <Route path="/signIn" component={CognitoLanding} />
            <Route path="/signUp" component={CognitoLanding} />
            <PrivateRoute path={"/events/:id/:mode"} component={<WrappedEventContainer />} />
            <PrivateRoute path={"/events/new"} component={<WrappedEventContainer />} />
            <PrivateRoute path={"/account/:mode"} component={<WrappedAccountCointainer />} />
            <PrivateRoute path={"/events"} component={<WrappedEventsList />} />
            <Redirect from="/" to="/events" />
        </Switch>
    );
}