import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify} from "aws-amplify";

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        cookieStorage: {
            domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
            secure: process.env.REACT_APP_COOKIE_STORAGE_SECURE,
            path: "/",
            expires: 365
        },
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            scope: ['openid', 'email', 'profile', 'phone', 'aws.cognito.signin.user.admin' ],
            redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,  
            redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT, 
            responseType: 'code',
        }
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_PHOTOS_BUCKET,
            region: 'eu-west-1'
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
