import React, { useEffect, useRef } from 'react';
import axios from "axios";

const ReCaptcha = ({ siteKey, action, callback }) => {
    const containerRef = useRef(null);
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

    async function handleCaptchaResponse(token) {
        const url = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.post(url + '/user/captcha', { token });
        callback(response.data)
    }

    useEffect(() => {
        if (window.grecaptcha && window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(() => {
                window.grecaptcha.enterprise.execute(captchaKey, { action }).then((token) => {
                    if (callback) handleCaptchaResponse(token);
                });
            });
        }
    }, [action, callback, captchaKey]);

    return <div ref={containerRef}></div>;
};

export default ReCaptcha;
