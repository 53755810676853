import React from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { deleteEvent, resetDeletedEvent } from "../../redux/actions/events";

const AccountAside = ({ eventId, onPageSelected, mode, ...props }) => {
    const { t } = useTranslation();

    return (
        <>
            <aside className="menu bg-gray is-hidden-touch">
                <ul className="menu-list">
                    <li><Link to={"/account/basic"} className={mode == "basic" ? "is-active" : ""} >{t('account.sideNavigation.basic')}</Link></li>
                    <li><Link to={"/account/credit"} className={mode == "credit" ? "is-active" : ""} >{t('account.sideNavigation.credit')}</Link></li>
                    {/* <li><Link to={"/account/funding"} className={mode == "funding" ? "is-active" : ""} >{t('account.sideNavigation.billing')}</Link></li> */}
                </ul>
            </aside>
        </>
    );
};

const mapStateToProps = state => (
    {
        events: state.eventsReducer.events,
        deletedEvent: state.eventsReducer.deletedEvent,
        loading: state.eventsReducer.loading
    }
);

const mapDispatchToProps =
{
    deleteEvent,
    resetDeletedEvent
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountAside));