import {
    CREATE_EVENT, DELETE_EVENT,
    DESELECT_EVENT,
    GET_ALL_EVENTS,
    GET_EVENT_BY_ID,
    RESET_CREATED_EVENT,
    RESET_DELETED_EVENT,
    RESET_UPDATED_EVENT,
    UPDATE_EVENT,
    LOADING_EVENT
} from "../actions/type";

const initialState = {
    events: [],
    selectedEvent: {},
    createdEvent: {},
    updatedEvent: {},
    deletedEvent: {},
    loading: false
};

const eventsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case GET_ALL_EVENTS:
            return {
                ...state,
                events: payload.Data
            };
        case CREATE_EVENT:
            return {
                ...state,
                events: [...state.events, payload],
                createdEvent: payload
            };
        case UPDATE_EVENT:
            const indexToUpdate = state.events.findIndex((e => e.id === payload.id));

            return {
                ...state,
                events: [
                    ...state.events.slice(0, indexToUpdate),
                    payload,
                    ...state.events.slice(indexToUpdate + 1)
                ],
                updatedEvent: payload.Data
            };
        case GET_EVENT_BY_ID:
            let event = state.events.find(e => e.id === payload);
            return {
                ...state,
                selectedEvent: event
            };
        case DESELECT_EVENT:
            return {
                ...state,
                selectedEvent: {}
            };
        case RESET_CREATED_EVENT:
            return {
                ...state,
                createdEvent: {}
            };
        case RESET_UPDATED_EVENT:
            return {
                ...state,
                updatedEvent: {}
            };
        case DELETE_EVENT:
            const indexToDelete = state.events.findIndex((e => e.id === payload));

            return {
                ...state,
                events: [
                    ...state.events.slice(0, indexToDelete),
                    ...state.events.slice(indexToDelete + 1)
                ],
                deletedEvent: payload.Data
            };
        case RESET_DELETED_EVENT:
            return {
                ...state,
                deletedEvent: {}
            }
        case LOADING_EVENT:
            return {
                ...state,
                loading: payload
            }
        default:
            return state;
    }
};

export default eventsReducer;