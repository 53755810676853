import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const MyChart = ({ credits, loading }) => {

    const { t } = useTranslation();

    const labels = []
    const quantities = []
    credits?.slice().reverse().map((c) => {
        labels.push(c.operationDate);
        quantities.push(c.quantity)
    });
    const options = {
        title: {
            display: false, // Oculta el título del gráfico
        },
        legend: {
            display: false, // Oculta la leyenda
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Esto eliminará las líneas de cuadrícula del eje X
                }
            },
            y: {
                grid: {
                    display: false, // Esto eliminará las líneas de cuadrícula del eje Y
                }
            }
        },
        maintainAspectRatio: false
    };

    const data = {
        labels: labels,
        datasets: [
            {
                label: "",
                data: quantities,
                backgroundColor: 'rgba(145, 70, 255, 1)',
                borderRadius: 25
            }
        ],
    };

    return (<>
        <h3 className="mb-1">{t('account.credit.useStats')}</h3>
        {loading
            ? <div className="content-main"><progress className="progress is-large is-primary"></progress></div>
            : credits?.length > 0
                ? <div className='column is-11' style={{ height: 400 }}><Bar options={options} data={data} /></div>
                : <center className='column is-11 m-2'>{t('account.credit.noUse')}</center>}

    </>)
}

export default MyChart;