import React from "react";
import { withRouter } from "react-router-dom";
import AccountForm from "../../components/account/accountForm";

const AccountBasicData = ({ user, loading, postUser }) => {

    return (
        loading
            ? <div className="content-main"><progress className="progress is-large is-primary"></progress></div>
            : <AccountForm user={user} postUser={postUser} loading={loading}/>
    );
};

export default withRouter(AccountBasicData);