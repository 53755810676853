import { useTranslation } from "react-i18next";

const Filter = ({ setTo, setFrom, onFilter }) => {
    
    const { t } = useTranslation();
    
    return <div className="columns">
        <div className='column is-5'>
            <div className="field">
                <label htmlFor="dateTo" className="label">{t('account.credit.from')}</label>
                <div className="control">
                    <input className="input" type="date"
                        placeholder={t('event.basicData.datePlaceholder')}
                        onChange={(e) => setFrom(Math.floor(Date.parse(e.target.value) / 1000))} />
                </div>
            </div>
        </div>
        <div className='column is-5'>
            <div className="field">
                <label htmlFor="dateTo" className="label">{t('account.credit.to')}</label>
                <div className="control">
                    <input className="input" type="date"
                        placeholder={t('event.basicData.datePlaceholder')}
                        onChange={(e) => {
                            let date = new Date(e.target.value);
                            date.setHours(23, 59, 59, 999);
                            setTo(Math.floor(date.getTime() / 1000))
                        }} />
                </div>
            </div>
        </div>
        <div className='column is-2'>
            <button className="button is-primary mt-2" aria-haspopup="true" aria-controls="dropdown-menu6" onClick={onFilter}>
                <label className="">
                    {t('account.credit.filter')}
                </label>
            </button>
        </div>
    </div>
}
export default Filter;