import api from "./api";
import { Auth } from "aws-amplify";

class UsersService {
    getUser() {
        return api.get('/user');
    };

    postUser(user) {
        return api.post('/user/update', user);
    };
    
    signOut() {
        return Auth.signOut();
    };
    
}

export default new UsersService();