import {
    GET_USER, RESET_SIGN_IN_ERROR_MESSAGE, SIGN_OUT, LOADING_USER, POST_USER
} from "./type";
import UsersService from "../../services/usersService";

export const getUser = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_USER,
            payload: true
        })
        const response = await UsersService.getUser();

        const balance = response.data.Data.totalBalance - response.data.Data.usedBalance;
        response.data.Data.balance = balance.toString();

        dispatch({
            type: GET_USER,
            payload: response.data
        });
        dispatch({
            type: LOADING_USER,
            payload: false
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: LOADING_USER,
            payload: false
        })
    }
};

export const postUser = (user) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_USER,
            payload: true
        })
        const response = await UsersService.postUser(user);
        dispatch({
            type: POST_USER,
            payload: response.data
        });
        dispatch({
            type: LOADING_USER,
            payload: false
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: LOADING_USER,
            payload: false
        })
    }
};

export const signOut = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_USER,
            payload: true
        })
        await UsersService.signOut();
        dispatch({
            type: SIGN_OUT,
            payload: {}
        });
        dispatch({
            type: LOADING_USER,
            payload: false
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: LOADING_USER,
            payload: false
        })
    }
};

export const resetErrorMessage = () => async (dispatch) => {
    dispatch({
        type: RESET_SIGN_IN_ERROR_MESSAGE
    });
};