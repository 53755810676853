import {CLEAN_FTP_USERS, CREATE_FTP_USER, GET_ALL_FTP_USERS_BY_EVENT_ID} from "./type";
import FtpUsersService from "../../services/ftpUsersService";

export const getAllFtpUsersByEvent = (eventId) => async (dispatch) => {
    try {
        const response = await FtpUsersService.getAllByEventId(eventId);
        dispatch({
            type: GET_ALL_FTP_USERS_BY_EVENT_ID,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};

export const cleanFtpUsers = () => async (dispatch) => {
    dispatch({
        type: CLEAN_FTP_USERS
    });
};

export const createFtpUser = (eventId, ftpUser) => async (dispatch) => {
    try {
        const response = await FtpUsersService.createFtpUser(eventId, ftpUser);

        dispatch({
            type: CREATE_FTP_USER,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
    }
};