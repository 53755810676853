import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const Footer = (props) => {
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    return <footer className="footer-main">
            <div className="container">
                © Picblink 2023
            </div>
        </footer>
};

const mapStateToProps = state => (
    {
        user: state.usersReducer.user
    }
);

export default withRouter(connect(mapStateToProps, null)(Footer));