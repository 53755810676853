import { useTranslation } from "react-i18next";

const BarBalance = ({credits}) => {
    const { t } = useTranslation();

    return (<>
        <h3 className="mb-1">{t('account.credit.balance')}</h3>
        <div className="columns">
            <div className="column is-10"><progress className="progress is-large is-primary" value={credits?.usedBalance || 0} max={credits?.totalBalance || 0}></progress></div>
            <div className="column is-2"><label className="pl-2"><strong>{credits?.usedBalance || 0}</strong>/{credits?.totalBalance || 0}</label></div>
        </div>
    </>)
}
export default BarBalance;