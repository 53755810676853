import api from "./api";

class StatsService {
    getStatsByEventId(eventId) {
        return api.get(`/event/${eventId}/stats`);
    };

    getCreditStats(params) {
        return api.post("/account/credit/stats", params);
    };

    getNumberOfDeliveredPhotos(eventId) {
        return api.get("/event/" + eventId + "/delivered-photos");
    };

    getNumberOfIndexedFaces(eventId) {
        return api.get("/event/" + eventId + "/indexes-faces");
    };

    getNumberOfPhotos(eventId) {
        return api.get("/event/" + eventId + "/number-photos");
    };

    getNumberOfSelfies(eventId) {
        return api.get("/event/" + eventId + "/number-selfies");
    };

    getNumberOfUsersAccessByCode(eventId) {
        return api.get("/event/" + eventId + "/users-access");
    };

    getNumberOfUsersWithMatch(eventId) {
        return api.get("/event/" + eventId + "/users-match");
    };

}

export default new StatsService();