import axios from "axios";
import { Auth } from 'aws-amplify';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

axiosInstance.interceptors.request.use(async config => {
    const session = await Auth.currentSession();
    config.headers = { "Authorization": session.getIdToken().getJwtToken() };
    return config;
},
    error => Promise.reject(error));

export default axiosInstance;