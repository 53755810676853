import React, { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/routerConfig";
import './i18n';

const App = () => {

    return (
        <div>
            <Suspense fallback={null}>
                <Provider store={store}>
                    <BrowserRouter>
                        <RouterConfig />
                    </BrowserRouter>
                </Provider>
            </Suspense>
        </div>
    );
}

export default App;
