import {
    CHANGE_PASSWORD,
    GET_USER,
    RESET_SIGN_IN_ERROR_MESSAGE,
    SIGN_IN,
    SIGN_UP,
    SIGN_OUT,
    LOADING_USER,
    POST_USER
} from "../actions/type";

const initialState = {
    user: undefined,
    errorMessage: undefined,
    loading: false
};

const usersReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SIGN_IN:
            return {
                ...state,
                user: payload.Data,
                errorMessage: undefined
            };
        case SIGN_UP:
            return {
                ...state,
                user: payload,
                errorMessage: undefined
            };
        case CHANGE_PASSWORD:
            return state;
        case SIGN_OUT:
            return {
                ...state,
                user: undefined,
                errorMessage: undefined
            };
        case GET_USER:
            return {
                ...state,
                user: payload.Data,
                errorMessage: undefined
            };
        case POST_USER:
            return {
                ...state,
                user: payload,
                errorMessage: undefined
            };
        case RESET_SIGN_IN_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: undefined
            };
        case LOADING_USER:
            return {
                ...state,
                loading: payload
            }
        default:
            return state;
    }
};

export default usersReducer;