import React, {useEffect, useState} from "react";
import {getAllFtpUsersByEvent, cleanFtpUsers} from "../../../redux/actions/ftpUsers";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import NewFtpUserDialog from "../../../components/events/newFtpUserDialog";

const EventFtpUsers = ({eventId, ...props}) => {
    const [showNewFtpUser, setShowNewFtpUser] = useState(false);

    useEffect(() => {
        props.cleanFtpUsers();
        if (eventId !== undefined)
            props.getAllFtpUsersByEvent(eventId);
    }, []);

    const showNewFtpUserDialog = (e) => {
        if (e !== undefined)
            e.preventDefault();
        setShowNewFtpUser(true);
    }

    const hideNewFtpUserDialog = (e) => {
        if (e !== undefined)
            e.preventDefault();
        setShowNewFtpUser(false);
    }

    return (
        <>
            <div className="columns">
                <div className="column is-6-tablet">
                    <h2 className="mb-1">Usuarios FTP</h2>
                </div>
                <div className="column is-6-tablet is-right-tablet">
                    <button className="button is-primary " onClick={showNewFtpUserDialog}>
                        Nuevo usuario FTP
                    </button>
                </div>
            </div>
            <table className="table is-fullwidth is-striped mt-1">
                <thead>
                <tr>
                    <th>Nombre de usuario</th>
                    <th className="has-text-right">Acciones</th>
                </tr>
                </thead>
                <tbody>
                {props.ftpUsers.map((ftpUser) => {
                    return (
                        <tr key={ftpUser.name}>
                            <td>
                                <a className="d-flex">
                                    <img className="is-avatar-sm"
                                         src="https://rockthesportv2.blob.core.windows.net/configuracion/mockups/picblink/images/fotografo.jpg"
                                         alt="Admin"/>
                                    <span>{ftpUser.name}</span>
                                </a>
                            </td>
                            <td className="has-text-right">
                                <div className="dropdown is-hoverable is-right">
                                    <div className="dropdown-trigger">
                                        <button className="button has-icon" aria-haspopup="true"
                                                aria-controls="dropdown-menu">
                                  <span className="material-icons">
                                    more_horiz
                                    </span>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a href="#" className="dropdown-item">
                                                Editar
                                            </a>
                                            <a href="#" className="dropdown-item">
                                                Reenviar acceso FTP
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {showNewFtpUser && <NewFtpUserDialog eventId={eventId} onClose={hideNewFtpUserDialog}/>}
        </>
    );
};

const mapStateToProps = state => (
    {
        ftpUsers: state.ftpUsersReducer.ftpUsers
    }
);

const mapDispatchToProps =
    {
        getAllFtpUsersByEvent,
        cleanFtpUsers
    }
;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventFtpUsers));