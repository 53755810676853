import {
    GET_STATS_BY_EVENT_ID, CLEAN_EVENT_STATS, LOADING_STATS, LOADING_STATS_HISTORY, LOADING_STATS_CHART,
    GET_CREDIT_STATS, GET_CREDIT_FOR_CHART, GET_CREDIT_FOR_HISTORY,
    GET_NUMBER_OF_DELIVERED_PHOTOS,
    GET_NUMBER_OF_INDEXES_FACES,
    GET_NUMBER_OF_PHOTOS,
    GET_NUMBER_OF_SELFIES,
    GET_NUMBER_OF_USERS_ACCESS_BY_CODE,
    GET_NUMBER_OF_USERS_WITH_MATCH,
} from "../actions/type";

const initialState = {
    numberOfPhotos: undefined,
    numberOfIndexedFaces: undefined,
    numberOfUsersAccessByCode: undefined,
    numberOfUsersWithMatch: undefined,
    numberOfDeliveredPhotos: undefined,
    numberOfSelfies: undefined,
    credits: undefined,
    creditsChart: undefined,
    creditsHistory: undefined,
    loading: false,
    loadingChart: false,
    loadingHistory: false,
};

const statsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_STATS_BY_EVENT_ID:
            return {
                ...state,
                numberOfPhotos: payload.Data.photos, //Fotos subidas en este evento
                numberOfIndexedFaces: payload.Data.indexedFaces, //Caras indexadas en todas las fotos
                numberOfUsersAccessByCode: payload.Data.users, //Usuarios únicos que han accedido
                numberOfUsersWithMatch: payload.Data.usersWithMatch, //Usuarios únicos que han encontrado fotos
                numberOfDeliveredPhotos: payload.Data.delivered, //Fotos únicas entregadas a usuario
                numberOfSelfies: payload.Data.selfies, //Selfies totales realizados
            };
        case GET_NUMBER_OF_DELIVERED_PHOTOS://Fotos únicas entregadas a usuario
            return {
                ...state,
                numberOfDeliveredPhotos: payload.Data,
            };
        case GET_NUMBER_OF_INDEXES_FACES://Caras indexadas en todas las fotos
            return {
                ...state,
                numberOfIndexedFaces: payload.Data,
            };
        case GET_NUMBER_OF_PHOTOS://Fotos subidas en este evento
            return {
                ...state,
                numberOfPhotos: payload.Data,
            };
        case GET_NUMBER_OF_SELFIES://Selfies totales realizados
            return {
                ...state,
                numberOfSelfies: payload.Data,
            };
        case GET_NUMBER_OF_USERS_ACCESS_BY_CODE://Usuarios únicos que han accedido
            return {
                ...state,
                numberOfUsersAccessByCode: payload.Data,
            };
        case GET_NUMBER_OF_USERS_WITH_MATCH://Usuarios únicos que han encontrado fotos
            return {
                ...state,
                numberOfUsersWithMatch: payload.Data,
            };
        case CLEAN_EVENT_STATS:
            return {
                ...state,
                numberOfPhotos: undefined,
                numberOfIndexedFaces: undefined,
                numberOfUsersAccessByCode: undefined,
                numberOfUsersWithMatch: undefined,
                numberOfDeliveredPhotos: undefined,
                numberOfSelfies: undefined,
            };
        case GET_CREDIT_STATS:
            return {
                ...state,
                credits: payload,
            };
        case GET_CREDIT_FOR_CHART:
            return {
                ...state,
                creditsChart: payload,
            };
        case GET_CREDIT_FOR_HISTORY:
            return {
                ...state,
                creditsHistory: payload,
            };
        case LOADING_STATS:
            return {
                ...state,
                loading: payload
            };
        case LOADING_STATS_CHART:
            return {
                ...state,
                loadingChart: payload
            };
        case LOADING_STATS_HISTORY:
            return {
                ...state,
                loadingHistory: payload
            };
        default:
            return state;
    }
};

export default statsReducer;