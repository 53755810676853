import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Storage } from "aws-amplify";
import { isPngExtension } from "../../../helpers/functions";
import Modal from "../../../components/generic/modal";
import { useHistory } from "react-router-dom";

const EventSponsors = ({ eventId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const portraitImageInputRef = useRef(null);
    const landscapeImageInputRef = useRef(null);
    const [portraitImageSrc, setPortraitImageSrc] = useState(undefined);
    const [landscapeImageSrc, setLandscapeImageSrc] = useState(undefined);
    const [portraitSelectedImage, setPortraitSelectedImage] = useState(undefined);
    const [landscapeSelectedImage, setLandscapeSelectedImage] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(async () => {
        const landscapeImagePath = `${eventId}/Configuration/SponsorLandscape.png`;
        const portraitImagePath = `${eventId}/Configuration/SponsorPortrait.png`;

        // comprobar si existe, URL firmada viene aunque no exista objeto en S3 (404)
        let existsLandscape = false;
        let existsPortrait = false;
        await Storage.list(`${eventId}/Configuration/`, { pageSize: 99 })
            .then((result) => {
                result.results?.map((item) => {
                    if (item.key.includes('SponsorLandscape.png')) existsLandscape = true;
                    if (item.key.includes('SponsorPortrait.png')) existsPortrait = true;
                })
            })
            .catch((err) => console.log(err))

        // Recuperar imagen(es) sponsor
        existsLandscape && await Storage.get(landscapeImagePath)
            .then(signedUrl => {
                setLandscapeImageSrc(signedUrl);
            })
            .catch(error => {
                console.log(error);
            });

        existsPortrait && await Storage.get(portraitImagePath)
            .then(signedUrl => {
                setPortraitImageSrc(signedUrl);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const dispatchUploadImage = async (isPortrait, image) => {
        const path = `${eventId}/Configuration/${isPortrait ? 'SponsorPortrait' : 'SponsorLandscape'}.png`;
        await Storage.put(path, image);
    };

    const dispatchDeleteImage = async (isPortrait) => {
        try {
            const basePath = `${eventId}/Configuration/`;
            const key = isPortrait ? 'SponsorPortrait.png' : 'SponsorLandscape.png';
            const path = `${basePath}${key}`;

            await Storage.remove(path);
        } catch (error) {
            console.log(error);
        }
    };

    const clearLandscapeImageInput = () => {
        setLandscapeSelectedImage(undefined);
        setLandscapeImageSrc(undefined);
        landscapeImageInputRef.current.value = "";
    };

    const clearPortraitImageInput = () => {
        setPortraitSelectedImage(undefined);
        setPortraitImageSrc(undefined);
        portraitImageInputRef.current.value = "";
    };

    const onLandscapeImageSelected = (e) => {
        const selectedImage = e.target.files[0];

        if (isPngExtension(selectedImage.name)) {
            if (FileReader) {
                let fileReader = new FileReader();
                fileReader.onload = function () {
                    const landscapeImage = new Image();
                    landscapeImage.onload = function () {
                        if (this.height === 640 && this.width === 960) {
                            setLandscapeSelectedImage(selectedImage);
                            setLandscapeImageSrc(fileReader.result);
                        } else {
                            clearLandscapeImageInput();
                            setErrorMessage(t('event.sponsors.invalidLandscapeMessage'));
                            setShowModal(true);
                        }
                    };
                    landscapeImage.src = fileReader.result;
                };
                fileReader.readAsDataURL(selectedImage);
            }
        } else {
            clearLandscapeImageInput();
            setErrorMessage(t('event.sponsors.invalidFormatMessage'));
            setShowModal(true);
        }
    };

    const onPortraitImageSelected = (e) => {
        const selectedImage = e.target.files[0];

        if (isPngExtension(selectedImage.name)) {
            setPortraitSelectedImage(selectedImage);
            if (FileReader) {
                let fileReader = new FileReader();
                fileReader.onload = function () {
                    const portraitImage = new Image();
                    portraitImage.onload = function () {
                        if (this.height === 960 && this.width === 640) {
                            setPortraitSelectedImage(selectedImage);
                            setPortraitImageSrc(fileReader.result);
                        } else {
                            clearPortraitImageInput();
                            setErrorMessage(t('event.sponsors.invalidPortraitMessage'));
                            setShowModal(true);
                        }
                    };
                    portraitImage.src = fileReader.result;
                };
                fileReader.readAsDataURL(selectedImage);
            }
        } else {
            clearPortraitImageInput();
            setErrorMessage(t('event.sponsors.invalidFormatMessage'));
            setShowModal(true);
        }
    };

    const onModalCancel = () => {
        setShowModal(false);
    };

    const dispatchCancel = () => {
        history.replace('/events');
    };

    const dispatchSubmit = async () => {
        if (portraitSelectedImage !== undefined) {
            await dispatchUploadImage(true, portraitSelectedImage);
        } else {
            if (portraitImageSrc === undefined) await dispatchDeleteImage(true);
        }

        if (landscapeSelectedImage !== undefined) {
            await dispatchUploadImage(false, landscapeSelectedImage);
        } else {
            if (landscapeImageSrc === undefined) await dispatchDeleteImage(false);
        }

        history.replace(`/events`);
    };

    return (
        <div>
            <div className="columns">
                <div className="column is-6-tablet">
                    <h2>{t('event.sponsors.title')}</h2>
                    <p>{t('event.sponsors.subtitle')}</p>
                </div>
            </div>
            <form>
                <div className="columns is-multiline">
                    <div className="column is-4-desktop is-12-tablet">
                        <div className="field">
                            <label className="label">{t('event.sponsors.landscapeImage')}</label>
                            <p className="mt-1">
                                <label htmlFor="file-upload-landscape" className="button">
                                    {t('generic.selectFileButton.text')}
                                </label>
                                <input id="file-upload-landscape" type="file" style={{ display: 'none' }}
                                    ref={landscapeImageInputRef}
                                    onChange={onLandscapeImageSelected} />
                            </p>
                            <p className="help mb-1">{t('event.sponsors.landscapeImageRequirements')}</p>
                            {landscapeImageSrc && <>
                                <figure className="image is-relative is-sponsor">
                                    <button type="button" className="button is-rounded is-delete" onClick={e => {
                                        e.preventDefault();
                                        clearLandscapeImageInput();
                                    }
                                    }>
                                        <i className="material-icons">close</i>
                                    </button>
                                    <img src={landscapeImageSrc} />
                                </figure>
                            </>}
                        </div>
                    </div>

                    <div className="column is-4-desktop is-12-tablet">
                        <div className="field">
                            <label className="label">{t('event.sponsors.portraitImage')}</label>
                            <p className="mt-1">
                                <label htmlFor="file-upload-portrait" className="button">
                                    {t('generic.selectFileButton.text')}
                                </label>
                                <input id="file-upload-portrait" type="file" style={{ display: 'none' }}
                                    ref={portraitImageInputRef}
                                    onChange={onPortraitImageSelected} />
                            </p>
                            <p className="help mb-1">{t('event.sponsors.portraitImageRequirements')}</p>
                            {portraitImageSrc && <>
                                <figure className="image is-relative is-sponsor">
                                    <button type="button" className="button is-rounded is-delete" onClick={e => {
                                        e.preventDefault();
                                        clearPortraitImageInput();
                                    }
                                    }>
                                        <i className="material-icons">close</i>
                                    </button>
                                    <img src={portraitImageSrc} />
                                </figure>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="mb-xs-6" />
                <div className="buttons-end is-xs-fixed mt-2">
                    <div className="buttons">
                        <button className="button is-fullwidth-mobile" onClick={e => {
                            e.preventDefault();
                            dispatchCancel();
                        }}>{t('generic.cancelButton.text')}</button>
                        <button
                            className="button is-primary is-fullwidth-mobile" type="button" onClick={e => {
                                e.preventDefault();
                                dispatchSubmit();
                            }}>{t('generic.saveButton.text')}</button>
                    </div>
                </div>
            </form>
            {showModal === true &&
                <Modal
                    title={t('generic.errorAlert.title')}
                    message={errorMessage}
                    showConfirmButton={false}
                    showCancelButton={true}
                    cancelButtonText={t('generic.alertButton.close')}
                    onCancel={onModalCancel}
                />}
        </div>
    );
};

export default EventSponsors;