import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ERROR_TOAST_CONFIG, isEmptyObject, isValidImageExtension } from "../../helpers/functions";
import { Storage } from "aws-amplify";
import { toast } from 'react-toastify';

const EventForm = ({ event, dispatchCreateOrUpdate, dispatchCancel, loading }) => {
    const { t } = useTranslation();
    const imageInputRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(undefined);
    const [selectedImage, setSelectedImage] = useState(undefined);

    const date = new Date();
    const today = moment(date).format('YYYY-MM-DD')

    const { register, watch, setValue, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            "name": "",
            "dateFrom": today,
            "dateTo": today,
            "eventType": t('event.basicData.eventType.race'),
            "active": true,
            "multiDate": false,
            "hasCustomPosterImage": false
        }
    });
    const multiDate = watch("multiDate");

    useEffect(() => {
        if (!isEmptyObject(event)) {
            const dateFrom = moment(event.dateFrom).format('YYYY-MM-DD');
            const dateTo = moment(event.dateTo).format('YYYY-MM-DD');
            let multiDate = !moment(dateFrom).isSame(dateTo, 'day');
            reset({
                "code": event.code,
                "name": event.name,
                "dateFrom": dateFrom,
                "dateTo": dateTo,
                "eventType": event.eventType,
                "active": event.active,
                "multiDate": multiDate,
                "hasCustomPosterImage": event.hasCustomPosterImage
            });
            if (event.hasCustomPosterImage === true) {
                const posterImagePath = `${event.id}/Configuration/${event.posterImageName}`;
                Storage.get(posterImagePath)
                    .then(signedUrl => {
                        setImageSrc(signedUrl);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                setImageSrc(undefined);
            }
        }
    }, [event]);

    const onSubmit = (data) => {
        const dateFrom = moment(data.dateFrom + "T10:00:00");
        const dateTo = !multiDate ? moment(data.dateFrom + "T10:01:00") : moment(data.dateTo + "T10:01:00");
        dispatchCreateOrUpdate(event === undefined, data.name, data.code, dateFrom.toISOString(), dateTo.toISOString(), data.eventType, selectedImage, data.active, data.hasCustomPosterImage);
    };

    const onError = (err, e) => console.log(err, e);

    const onCopyCode = () => {
        navigator.clipboard.writeText(event.code);
        toast(t('nav.menu.copyText'), {...ERROR_TOAST_CONFIG, autoClose: 3000});
    };

    const clearImageInput = () => {
        setValue('hasCustomPosterImage', false);
        imageInputRef.current.value = "";
        setImageSrc(undefined);
    };

    const onImageSelected = (e) => {
        const selectedImage = e.target.files[0];

        if (isValidImageExtension(selectedImage.name)) {
            setSelectedImage(selectedImage);
            if (FileReader) {
                let fileReader = new FileReader();
                fileReader.onload = function () {
                    setImageSrc(fileReader.result);
                    setValue('hasCustomPosterImage', true);
                };
                fileReader.readAsDataURL(selectedImage);
            }
        } else {
            clearImageInput();
            setSelectedImage(undefined);
            // TODO: Mostrar un mensaje
            alert("Formato no compatible");
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <h2 className="mb-1">{t('event.basicData.title')}</h2>
                {event !== undefined &&
                    <div className="columns mb-1">
                        <div className="column is-auto-tablet">
                            <label className="label">{t('event.basicData.code.label')}</label>
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <input className="input" type="text" placeholder="" disabled {...register('code')} />
                                </div>
                                <div className="control">
                                    <button className="button" onClick={e => {
                                        e.preventDefault();
                                        onCopyCode();
                                    }}>
                                        <i className="material-icons">content_copy</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="field">
                    <label htmlFor="name" className="label">{t('event.basicData.nameInput.label')}</label>
                    <div className="control">
                        <input data-testid="name" id="name" className="input" type="text"
                            placeholder={t('event.basicData.nameInput.placeholder')}
                            {...register('name', { required: t('event.basicData.validationRequired') })} />
                        {errors.name && <span role="alert">{errors.name.message}</span>}
                    </div>
                </div>
                <div className="columns mb-1">
                    <div className={multiDate ? "column is-5" : "column is-10"}>
                        <div className="field">
                            <label htmlFor="dateFrom"
                                className="label">{t('event.basicData.startDateInput.label')}</label>
                            <div className="control">
                                <input data-testid="dateFrom" id="dateFrom" className="input" type="date"
                                    placeholder={t('event.basicData.datePlaceholder')}
                                    {...register("dateFrom", {
                                        required: t('event.basicData.validationRequired'),
                                        validate: date => moment(date).isValid()
                                    })} />
                                {errors.dateFrom && <span role="alert">{errors.dateFrom.message}</span>}
                            </div>
                        </div>
                    </div>
                    {multiDate &&
                        <div className={multiDate ? "column is-5" : "column is-half"}>
                            <div className="field">
                                <label htmlFor="dateTo" className="label">{t('event.basicData.endDateInput.label')}</label>
                                <div className="control">
                                    <input data-testid="dateTo" id="dateTo" className="input" type="date"
                                        placeholder={t('event.basicData.datePlaceholder')}
                                        {...register("dateTo", {
                                            required: t('event.basicData.validationRequired'),
                                            validate: date => moment(date).isValid()
                                        })} />
                                    {errors.dateTo && <span role="alert">{errors.dateTo.message}</span>}
                                </div>
                            </div>
                        </div>}
                    <div className="column is-2">
                        <div className="field">
                            <label className="label">{t('event.basicData.multiDate.label')}</label>
                            <div className="d-flex">
                                <div className="onoffswitch mr-1">
                                    <input data-testid="multiDate" type="checkbox" name="multiDate"
                                        className="onoffswitch-checkbox"
                                        id="multiDate" tabIndex="0"
                                        key={multiDate ? "active-1" : "active-2"}
                                        {...register("multiDate")} />
                                    <label className="onoffswitch-label" htmlFor="multiDate">
                                        <span className="onoffswitch-inner" />
                                        <span className="onoffswitch-switch" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="field">
                    <label htmlFor="eventType" className="label">{t('event.basicData.eventTypeSelect.label')}</label>
                    <div className="control">
                        <div className="select is-fullwidth">
                            <select data-testid="eventType"
                                id="eventType" {...register("eventType", {
                                    required: t('event.basicData.validationRequired')
                                })}>
                                <option key="type-race"
                                    value={t('event.basicData.eventType.race')}>{t('event.basicData.eventType.race')}</option>
                                <option key="type-other"
                                    value={t('event.basicData.eventType.other')}>{t('event.basicData.eventType.other')}</option>
                            </select>
                        </div>
                    </div>
                </div> */}

                {event !== undefined && <div className="field">
                    <label className="label">{t('event.basicData.headerImage.label')}</label>
                    <div className="columns">
                        <div className="column is-6-tablet">
                            <figure className="image is-relative">
                                {imageSrc &&
                                    <>
                                        <button type="button" className="button is-rounded is-delete" onClick={e => {
                                            e.preventDefault();
                                            clearImageInput();
                                        }}>
                                            <i className="material-icons">close</i>
                                        </button>
                                        <img alt="" src={imageSrc} />
                                    </>
                                }
                            </figure>
                            <p className="mt-1">
                                <label htmlFor="file-upload" className="button">
                                    {t('generic.selectFileButton.text')}
                                </label>
                                <input id="file-upload" type="file" style={{ display: 'none' }} ref={imageInputRef}
                                    onChange={onImageSelected} />
                            </p>
                            <p className="help mt-1">{t('event.basicData.headerImage.formats')}</p>
                        </div>
                    </div>
                </div>}
                <div className="mb-xs-6" />
                <div className="buttons-end is-xs-fixed mt-2">
                    <div className="buttons">
                        <button data-testid="cancel" className="button is-fullwidth-mobile"
                            onClick={e => {
                                e.preventDefault();
                                dispatchCancel();
                            }}>{t('generic.cancelButton.text')}
                        </button>
                        <button data-testid="saveOrCreate" type="submit"
                            className={loading ? "button is-loading is-primary is-fullwidth-mobile" : "button is-primary is-fullwidth-mobile"}>{event !== undefined ? t('generic.saveButton.text') : t('event.basicData.createButton.text')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default EventForm;