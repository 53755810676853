
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const History = ({ credits, getCreditForHistory, loading, setTo, setFrom }) => {

    const { t } = useTranslation();

    return (<div style={{ height: '65%' }}>
        <h3>{t('account.credit.history')}</h3>
        {loading
            ? <div className="content-main"><progress className="progress is-large is-primary"></progress></div>
            : <div className='column is-11'>
                <table className="table is-fullwidth is-striped">
                    <thead>
                        <tr>
                            <th>{t('account.credit.date')}</th>
                            <th className="is-hidden-touch">{t('account.credit.type')}</th>
                            <th className="is-hidden-touch">{t('account.credit.note')}</th>
                            <th className="is-hidden-touch">{t('account.credit.event')}</th>
                            <th className="is-hidden-touch">{t('account.credit.credit')}</th>
                            <th className="is-hidden-touch">{t('account.credit.current')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {credits?.map((credit, idx) => {
                            return (
                                <tr key={idx}>
                                    <td><span>{credit.operationDate}</span></td>
                                    <td><span>{t('account.credit.typeStat.' + credit.type)}</span></td>
                                    <td><span>{credit.note}</span></td>
                                    <td><span>{credit.eventName}</span></td>
                                    <td><span>{credit.quantity}</span></td>
                                    <td><span>{credit.totalBalance - credit.usedBalance}</span></td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>}
    </div>)
}

export default History;