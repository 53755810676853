import React, { useEffect, useState } from "react";
import { cleanEventStats, getNumberOfDeliveredPhotos, getNumberOfIndexedFaces, getNumberOfPhotos, getNumberOfSelfies, getNumberOfUsersAccessByCode, getNumberOfUsersWithMatch } from "../../../redux/actions/stats";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";

const EventStats = ({ ...props }) => {

    const [help, setHelp] = useState(-1);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            if (!props.numberOfDeliveredPhotos) props.getNumberOfDeliveredPhotos(id);
            if (!props.numberOfIndexedFaces) props.getNumberOfIndexedFaces(id);
            if (!props.numberOfPhotos) props.getNumberOfPhotos(id);
            if (!props.numberOfSelfies) props.getNumberOfSelfies(id);
            if (!props.numberOfUsersAccessByCode) props.getNumberOfUsersAccessByCode(id);
            if (!props.numberOfUsersWithMatch) props.getNumberOfUsersWithMatch(id);
        }
        return () => {
            props.cleanEventStats();
        }
    }, []);

    return (
        <div>
            <h2 className="mb-1">Estadísticas</h2>
            <div className="columns is-multiline mt-1">
                <div className="column is-6-tablet is-4-desktop" onMouseOver={() => setHelp(0)} onMouseOut={() => setHelp(-1)}>
                    <div className='card stat3'>
                        <div className="card-content">
                            <div className="content">
                                <p className="has-text-centered">
                                    <span className="material-icons is-large has-color-gray">
                                        image
                                    </span>
                                </p>
                                {props.numberOfPhotos > -1
                                    ? <p className="qty is-large has-text-centered mt-1 mb-0">{props.numberOfPhotos}</p>
                                    : <progress className="progress is-large is-primary small"></progress>}
                                <div className="has-text-centered" style={{ height: 25 }}>
                                    {help === 0
                                        ? <span className="tag is-primary is-light">Fotos subidas en este evento</span>
                                        : <p className="has-text-centered">Fotos</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="column is-6-tablet is-4-desktop" onMouseOver={() => setHelp(1)} onMouseOut={() => setHelp(-1)}>
                    <div className="card stat3">
                        <div className="card-content">
                            <div className="content">
                                <p className="has-text-centered">
                                    <span className="material-icons is-large has-color-gray">
                                        face
                                    </span>
                                </p>
                                {props.numberOfIndexedFaces > -1
                                    ? <p className="qty is-large has-text-centered mt-1 mb-0">{props.numberOfIndexedFaces}</p>
                                    : <progress className="progress is-large is-primary small"></progress>}
                                <div className="has-text-centered" style={{ height: 25 }}>
                                    {help === 1
                                        ? <span className="tag is-primary is-light">Caras indexadas en todas las fotos</span>
                                        : <p className="has-text-centered">Caras</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-6-tablet is-4-desktop" onMouseOver={() => setHelp(2)} onMouseOut={() => setHelp(-1)}>
                    <div className="card stat3">
                        <div className="card-content">
                            <div className="content">
                                <p className="has-text-centered">
                                    <span className="material-icons is-large has-color-gray">
                                        person_add
                                    </span>
                                </p>
                                {props.numberOfUsersAccessByCode > -1
                                    ? <p className="qty is-large has-text-centered mt-1 mb-0">{props.numberOfUsersAccessByCode}</p>
                                    : <progress className="progress is-large is-primary small"></progress>}
                                <div className="has-text-centered" style={{ height: 25 }}>
                                    {help === 2
                                        ? <span className="tag is-primary is-light">Usuarios únicos que han accedido</span>
                                        : <p className="has-text-centered">Conectados</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-6-tablet is-4-desktop" onMouseOver={() => setHelp(3)} onMouseOut={() => setHelp(-1)}>
                    <div className="card stat3">
                        <div className="card-content">
                            <div className="content">
                                <p className="has-text-centered">
                                    <span className="material-icons is-large has-color-gray">
                                        person_search
                                    </span>
                                </p>
                                {props.numberOfUsersWithMatch > -1
                                    ? <p className="qty is-large has-text-centered mt-1 mb-0">{props.numberOfUsersWithMatch}</p>
                                    : <progress className="progress is-large is-primary small"></progress>}
                                <div className="has-text-centered" style={{ height: 25 }}>
                                    {help === 3
                                        ? <span className="tag is-primary is-light">Usuarios únicos que han encontrado fotos</span>
                                        : <p className="has-text-centered">Encontrados</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-6-tablet is-4-desktop" onMouseOver={() => setHelp(4)} onMouseOut={() => setHelp(-1)}>
                    <div className="card stat3">
                        <div className="card-content">
                            <div className="content">
                                <p className="has-text-centered">
                                    <span className="material-icons is-large has-color-gray">
                                        image_search
                                    </span>
                                </p>
                                {props.numberOfDeliveredPhotos > -1
                                    ? <p className="qty is-large has-text-centered mt-1 mb-0">{props.numberOfDeliveredPhotos}</p>
                                    : <progress className="progress is-large is-primary small"></progress>}
                                <div className="has-text-centered" style={{ height: 25 }}>
                                    {help === 4
                                        ? <span className="tag is-primary is-light">Fotos únicas entregadas a usuario</span>
                                        : <p className="has-text-centered">Entregas</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-6-tablet is-4-desktop" onMouseOver={() => setHelp(5)} onMouseOut={() => setHelp(-1)}>
                    <div className="card stat3">
                        <div className="card-content">
                            <div className="content is-fixed">
                                <p className="has-text-centered">
                                    <span className="material-icons is-large has-color-gray">
                                        camera_front
                                    </span>
                                </p>
                                {props.numberOfSelfies > -1
                                    ? <p className="qty is-large has-text-centered mt-1 mb-0">{props.numberOfSelfies}</p>
                                    : <progress className="progress is-large is-primary small"></progress>}
                                <div className="has-text-centered" style={{ height: 25 }}>
                                    {help === 5
                                        ? <span className="tag is-primary is-light">Selfies totales realizados</span>
                                        : <p className="has-text-centered">Selfies</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => (
    {
        numberOfPhotos: state.statsReducer.numberOfPhotos,
        numberOfIndexedFaces: state.statsReducer.numberOfIndexedFaces,
        numberOfUsersAccessByCode: state.statsReducer.numberOfUsersAccessByCode,
        numberOfUsersWithMatch: state.statsReducer.numberOfUsersWithMatch,
        numberOfDeliveredPhotos: state.statsReducer.numberOfDeliveredPhotos,
        numberOfSelfies: state.statsReducer.numberOfSelfies,
    }
);

const mapDispatchToProps =
{
    getNumberOfDeliveredPhotos,
    getNumberOfIndexedFaces,
    getNumberOfPhotos,
    getNumberOfSelfies,
    getNumberOfUsersAccessByCode,
    getNumberOfUsersWithMatch,
    cleanEventStats,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventStats));