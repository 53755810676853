import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import EventAside from "../navigation/eventAside";
import { getAllEvents, deleteEvent, resetDeletedEvent } from "../../redux/actions/events";
import { getUser } from "../../redux/actions/users";
import EventBasicData from "../../pages/events/event/eventBasicData";
import EventStats from "../../pages/events/event/eventStats";
import EventFtpUsers from "../../pages/events/event/eventFtpUsers";
import EventSponsors from "../../pages/events/event/eventSponsors";
import EventPhotos from "../../pages/events/event/eventPhotos";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { mobileWidthBulma } from "../../helpers/bulma";

const EventContainer = (props) => {
    let { id, mode } = useParams();

    useEffect(() => {
        if (props.user === undefined) {
            props.getUser();
        }
        props.getAllEvents();
    }, []);
    console.log(window.innerWidth)
    return (
        <main className="main">
            <section className={`section ${window.innerWidth > mobileWidthBulma ? 'section-has-sidebar' : ''}`}>
                <EventAside eventId={id} mode={mode} />
                <div className="content-main">
                    {mode === "basic" || !id ?
                        <EventBasicData id={id} /> : null}
                    {mode === "sponsor" ?
                        <EventSponsors eventId={id} /> : null}
                    {mode === "stats" ?
                        <EventStats eventId={id} /> : null}
                    {mode === "sftp" ?
                        <EventFtpUsers eventId={id} /> : null}
                    {mode === "photos" ?
                        <EventPhotos eventId={id} /> : null}
                </div>
            </section>
        </main>
    );
};

const mapStateToProps = state => (
    {
        user: state.usersReducer.user,
        loadingUser: state.usersReducer.loading,
        events: state.eventsReducer.events,
        selectedEvent: state.eventsReducer.selectedEvent,
        loading: state.eventsReducer.loading,
    }
);

const mapDispatchToProps =
{
    getAllEvents,
    getUser,
    deleteEvent,
    resetDeletedEvent
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventContainer));