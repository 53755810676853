import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyChart from "../../components/account/Chart";
import History from "../../components/account/History";
import BarBalance from "../../components/account/BarBalance";
import Filter from "../../components/account/Filter";


const AccountCredit = ({ credits, creditsChart, creditsHistory, getCreditStats, getCreditForChart, getCreditForHistory, loadingChart, loadingHistory }) => {

    const { t } = useTranslation();

    const [from, setFrom] = useState(undefined);
    const [to, setTo] = useState(undefined);
    const [pageSize, setPageSize] = useState('50');

    const onFilter = () => {
        getCreditStats({ pageSize: '1'});
        getCreditForHistory({ pageSize: pageSize, from: from, to: to });
        getCreditForChart({ pageSize: pageSize, from: from, to: to });
    }

    return (<>
        <h2 className="mb-1">{t('account.credit.title')}</h2>
        <BarBalance credits={credits} />
        <Filter to={to} setTo={setTo} from={from} setFrom={setFrom} onFilter={onFilter}/>
        <MyChart credits={creditsChart} getCreditForChart={getCreditForChart} loading={loadingChart}/>
        <History credits={creditsHistory} getCreditForHistory={getCreditForHistory} loading={loadingHistory}/>
    </>);
};

export default withRouter(AccountCredit);